.Evaluation {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(250, 250, 250);
  font-family: "M PLUS 1p", sans-serif;
}

.row {
  padding-bottom: 1.5rem;
}

.service-title {
  padding: 20px;
  text-align: center;
  font-weight: bold;
  color: pink;
}

.title {
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

.title-sub {
  padding: 5px 0px;
  font-weight: bold;
}

.img-icon-square {
  margin: 0;
  width: 24px;
  height: 24px;
}

.img-btn {
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    position: relative;
    top: 3px;
  }
}

.img-btn-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.select-none {
  user-select: none;
  -webkit-user-drag: none;
}
