.ServiceIndex {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(250, 250, 250);
  font-family: "M PLUS 1p", sans-serif;
}

.main-title {
  padding: 20px;
  text-align: center;
  font-weight: bold;
  color: pink;
}

.row {
  padding-bottom: 1.5rem;
}

.title {
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

.title-sub {
  padding: 5px 0px;
  font-weight: bold;
}

/* .btn-service{
  font-weight: bold;
  color: hotpink;
} */

.btn-service label {
  font-size: 1.5em;
  font-weight: bold;
  color: rgba(10, 10, 10, 0.8);
  text-shadow: 1px 1px 4px silver;
}

.btn-service-sub label {
  font-size: 1.2em;
  font-weight: bold;
  color: rgba(10, 10, 10, 0.8);
  text-shadow: 1px 1px 3px silver;
}

.logo-main {
  height: 40vmin;
  pointer-events: none;
}

.logo-service {
  height: 30vmin;
  pointer-events: none;
}

.logo-service-sub {
  height: 20vmin;
  pointer-events: none;
}
